.twitter-typeahead {
	width: 100%;
	.tt-menu {
		width: 100%;
		background: $theme-light;
		border: 1px solid $gray-100;
		border-radius: 5px;
		padding: 0.75rem 0;
		.tt-suggestion {
			padding: 0.25rem 0.75rem;
			cursor: pointer;
			&:hover {
				background-color: $primary;
				color: $white;
			}
		}
	}
	.empty-message {
		padding: 5px 10px;
		text-align: center;
	}
	.rtl-typeahead .tt-menu {
		text-align: right;
	}
	.league-name {
		margin: 0 10px 5px;
		padding: 7px 5px 10px;
		border-bottom: 1px solid $gray-200;
	}
}
.scrollable-dropdown .twitter-typeahead .tt-menu {
	max-height: 80px;
	overflow-y: auto;
}

.bootstrap-maxlength {
	margin-top: 0.5rem;
}

.card-invoice-detail {
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	z-index: 9;
	height: 100%;
	overflow-y: scroll;
	.border-dash-bottom {
		border-bottom: 1px dashed $border-color;
	}
}

.notes {
	display: flex;
	.nav {
		display: block;
		width: 350px;
	}
	.tab-content {
		width: 100%;
	}
	.nav-tabs {
		.nav-link {
			border-radius: 0;
			border: 1px solid $border-color;
			&.active {
				border-left: 4px solid $info;
				background: $light;
			}
		}
	}
}