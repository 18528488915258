/*these tooltips can work with any element eg buttons,div etc
*/

/*just create a beautiful button,this is a sample;*/

.tooltip1 {
  display: inline-block;
  position: relative;
  // border-bottom: 1px dotted #666;
  text-align: left;
  /*i used these margins to position tooltip around the conatainer so dont include them*/
  // margin-top: 150px;
  // margin-left: 100px;
}

.tooltip1 .tooltip-top {
  min-width: 200px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 20px;
  /*all of the tooltips tweaks the margins to fit you*/
  margin-top: 10px;
  color: #444444;
  background-color: #121212;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip1:hover .tooltip-top {
  visibility: visible;
  opacity: 1;
}
.tooltip1:focus .tooltip-top {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip1 .tooltip-top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*ends here and you can tweak it to fit your needs*/

/*right tolltip*/

.tooltip1 .tooltip-right {
  min-width: 500px;
  top: 50%;
  left: 100%;
  margin-left: 20px;
  transform: translate(0, -50%);
  padding: 10px 30px;
  color: #525252;
  background-color: $white;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $boder-color;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
  @media (max-width: 839px) {
    min-width: 200px;
    top: 35px;
    left: -80px;
    transform: translate(-50%, 0);
    padding: 10px 30px;
    color: #525252;
    background-color: $white;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid $boder-color;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    width: 100%;
  }
}

.tooltip1:hover .tooltip-right {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip1 .tooltip-right i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*end right*/

/*start left tip*/
.tooltip1 .tooltip-left {
  min-width: 200px;
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);
  padding: 10px 20px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip1:hover .tooltip-left {
  visibility: visible;
  opacity: 1;
}
.tooltip1:focus .tooltip-left {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-left i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip1 .tooltip-left i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*end left*/

/*start bottom*/

.tooltip1 .tooltip-bottom {
  min-width: 275px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  color: #525252;
  background-color: $white;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  border: 1px solid $boder-color;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;

  @media (max-width: 839px) {
    min-width: 200px;
    left: 50%;
    height: 100%;
    min-height: 150px;
    padding: 10px 20px;
    overflow: scroll;
  }
}

.tooltip1:hover .tooltip-bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip1 .tooltip-bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
