@font-face {
  font-family: "Open Sans";
  font-display: swap;
  src: url("/OpenSans/OpenSans.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: "Open Sans";
}
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Open Sans";
}
button {
  outline: none;
}

.Progress_Status {
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 25%;
  right: 25%;
  width: 50%;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: transparent;
  height: 20px;
}

.myprogressBar {
  width: 1%;
  height: 20px;
  background-color: #63d7c7;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 16px;
}

.double_bounce1,
.double_bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #63d7c7;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double_bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.spinner {
  display: block;
  width: 40px;
  height: 40px;
  top: 45%;
  position: absolute;
  display: none;
}
.loading_title {
  top: 45%;
  position: absolute;
  width: 300px;
  text-align: center;
  margin-top: 50px;
  font-size: 12px;
}
.viewer_not_found {
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 25%;
  right: 25%;
  width: 50%;
  text-align: center;
  display: none;
}
.viewer_not_found_text {
  font-size: 16px;
}

.container {
  display: grid;
  grid-template-columns: /*100px*/ 1fr;
  height: 100%;

  @media only screen and (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 100px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 100px;
  }
}

.thewiewerwindow {
  background-color: #EEEDEB;
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.rendercanvas {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  outline: none;
  overflow: hidden;
  @media only screen and (max-width: 839px) {
    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    outline: none;
    overflow: hidden;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    outline: none;
    overflow: hidden;
  }
}

.rendercanvas_revise {
  position: absolute;
  bottom: 250px;
  left: 200px;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  display: inline-block;
  outline: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 1201px) and (max-width: 1367px) {
    bottom: 125px;
    left: 75px;
    max-width: 800px;
  }
  @media only screen and (min-width: 2001px) {
    bottom: 425px;
    left: 750px;
    max-width: 800px;
  }
}


.header_content {
  position: absolute;
  top: 25px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 1;
  @media only screen and (max-width: 839px) {
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.toggle_dark_mode_container {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 10px;
  cursor: pointer;
  @media only screen and (max-width: 839px) {
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.display_desktop {
  @media only screen and (max-width: 839px) {
    display: none !important;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid !important;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 17px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 839px) {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 17px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 17px;
    margin-right: auto;
    margin-left: auto;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccbca;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &::before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .switch_slider {
  background-color: #6bcaba;
  &:focus {
    box-shadow: 0 0 1px #6bcaba;
  }
  &::before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}

.switch_slider.round {
  border-radius: 34px;
  &::before {
    border-radius: 50%;
  }
}

.dark_mode_title {
  color: #979796;
  font-size: 14px;
  cursor: pointer;
}

.steps_container {
  position: absolute;
  bottom: 130px;
  left: 50px;
  width: calc(100% - 100px);
  display: grid;
  height: 60px;
  padding-bottom: 5px;
  overflow-x: auto;
  overflow-y: visible;
  @media only screen and (max-width: 839px) {
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.steps_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  background-color: #44e2d0;
  height: 5px;
  width: 100%;
  margin-inline-start: 3px;
  cursor: pointer;
  min-width: 25px;
}

.allSteps {
  background-color: #44e2d0;
  height: 5px;
  width: 100%;
  margin-inline-start: 3px;
  cursor: pointer;
  min-width: 25px;
}

.emptyStep {
  background-color: transparent;
  height: 5px;
  width: 100%;
  margin-inline-start: 3px;
  cursor: pointer;
  min-width: 25px;
}

.passiveAligners {
  background-color: #cccccc;
  height: 5px;
  width: 100%;
  margin-inline-start: 3px;
  cursor: pointer;
  min-width: 25px;
}

.firstStep {
  background-color: gray !important;
  cursor: pointer;
}

.overCorrectionStepsNumber {
  background-color: #e84e1b !important;
}

.labels_content {
  position: relative;
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip_orange {
  position: absolute;
  top: -27px;
  background-color: #ffb801;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.tooltip_white {
  position: absolute;
  top: -24px;
  left: 3px;
  background-color: #ffb801;
  border: 2px solid #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 999;
}

.labels_content .tooltip_orange::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  margin-top: -1px;
  border-style: solid;
  border-color: #ffb801 transparent transparent transparent;
}
.label {
  height: 15px;
  width: 100%;
  color: #aaa9a8;
  font-size: 14px;
  text-align: center;
  margin-inline-start: 3px;
  cursor: pointer;
  min-width: 25px;
}

.play_pause_container {
  position: absolute;
  z-index: 1;
  bottom: 200px;
  display: grid;
  grid-template-columns: 36px 36px 48px 36px 36px;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  cursor: pointer;
  @media only screen and (max-width: 839px) {
    position: absolute;
    bottom: 125px;
    display: grid;
    grid-template-columns: 36px 36px 48px 36px 36px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none !important;
  }
}

.viewer_window_header {
  position: absolute;
  bottom: 17px;
  color: #0f0f0f;
  font-size: 20px;
  text-align: center;
  z-index: 1;
}

.viewer_window_sub_header {
  position: absolute;
  top: 42px;
  color: #0f0f0f;
  font-size: 14px;
  text-align: center;
  z-index: 1;
}

.upperNav_container {
  position: absolute;
  top: 47px;
  bottom: auto;
  border-radius: 30px;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 75px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 839px) { 
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}
.lowerNav_container {
  position: absolute;
  bottom: 40px;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  gap: 75px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 839px) { 
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.upperNav_content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 10px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  color: #2d2d2d;
  grid-row: 2;
}
.upperNav_content_moblie {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 10px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  color: #888888;
}

.upperNav_content_active {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 10px;
  text-align: center;
  cursor: pointer;
  color: #00a78c;
}

.lowerNav_content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 10px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  color: #2d2d2d;
  grid-row: 2;
}

.lowerNav_content_active {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 10px;
  text-align: center;
  cursor: pointer;
  color: #00a78c;
}

.navBar_mobile_container {
  @media only screen and (min-width: 840px) { 
    display: none;
  }
  display: grid;
  grid-template-columns: 80px 80px 80px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.sideBar_container {
  display: none;
  @media only screen and (max-width: 839px) {
    display: grid;
    align-content: start;
    background-color: #333333;
    text-align: center;
    -webkit-transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    -moz-transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    -o-transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    width: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1px;
    grid-column-gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 10px;
    order: 1;
    overflow-x: auto;
    overflow-y: hidden;
    transition: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: grid;
    align-content: start;
    background-color: #333333;
    text-align: center;
    -webkit-transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    -moz-transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    -o-transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    transition: all 0.5s cubic-bezier(0.44, 1.06, 0.43, 1.04);
    width: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr 1.5fr 1fr 3fr 1px;
    grid-column-gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    order: 1;
    overflow-x: auto;
    overflow-y: hidden;
    transition: none;
  }
}

.sideBar_icon {
  padding-top: 10px;
}
.sideBar_icon_upper {
  padding-top: 0px;
}
.sideBar_icon_lower {
  padding-top: 30px;
}
.sideBar_icon_title_mobile_upper {
  padding-top: 20px;
}
.sideBar_content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  margin: 0px 20px;
  @media only screen and (max-width: 839px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0px 7px 7px 7px;
    margin: auto;
    grid-gap: 0px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0px 7px 7px 7px;
    margin: auto;
    grid-gap: 0px;
  }
}
.upperNav_icon {
  object-fit: cover;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  @media only screen and (max-width: 839px) {
    object-fit: cover;
    margin-right: auto;
    margin-left: auto;
    width: 45px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    object-fit: cover;
    margin-right: auto;
    margin-left: auto;
    width: 45px;
  }
}
.upperNav_icon_title {
  color: #fff;
  font-size: 14px;
  text-align: start;
  @media only screen and (max-width: 839px) {
    font-size: 10px;
    text-align: center;
    padding-top: 5px;
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    color: #fff;
    font-size: 10px;
    text-align: center;
    display: none;
  }
}


.side_bar_details_title {
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: grid;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  margin: 0px 20px;
  border-bottom: 2px solid #4c4c4c;
  padding: 5px 0px;
  margin-bottom: 4px;
  @media only screen and (max-width: 839px) {
    display: none;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}
.lowerNav_icon_title {
  text-align: center;
  cursor: pointer;
}
.lowerNav_icon{
  justify-self: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.lowerNav_icon_upper{
  justify-self: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.lowerNav_icon_lower {
  justify-self: center;
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.marginTop {
  margin-top: 8px;
  @media only screen and (max-width: 839px) {
    margin-top: auto;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    margin-top: auto;
  }
}

.empty_div_onMobile {
  display: none;
  @media only screen and (max-width: 839px) {
    padding: 0px 1px 7px 0px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    padding: 0px 1px 7px 0px;
  }
}

.play_pause_container_landScape {
  height: 0px;
  display: none !important;
  @media only screen and (max-width: 839px) {
    height: 0px;
    display: none !important;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    z-index: 1;
    display: grid !important;
    grid-template-columns: 35px 35px 45px 35px 35px;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-bottom: 15px;
  }
}

.hidden_element {
  display: none;
}
.header_dot_container {
  display: none;
  @media only screen and (max-width: 839px) {
    display: block;
    position: absolute;
    top: 22px;
    right: 18px;
    z-index: 1;
    width: 30px;
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

.dot_container {
  display: grid;
  grid-gap: 5px;
  justify-content: center;
}

.dot {
  width: 7px;
  height: 7px;
  background-color: #000;
  border-radius: 50%;
}

.modal_hidden_on_desktop {
  display: none;
  @media only screen and (max-width: 839px) {
    display: block;
  }

  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    display: none;
  }
}

/* Modal Content */
.modal_content {
  // background-color: #fefefe;
  margin: auto;
  // padding: 20px;
  min-width: 230px;
}
.modal_title {
  text-align: center;
  color: #000;
  font-size: 20px;
  padding-bottom: 20px;
}

.modal_switch_container {
  display: grid;
  grid-template-columns: 4fr 1px 1fr;
  grid-gap: 10px;
  align-items: center;
}

.side_line {
  width: 100%;
  height: 100%;
  border-left: 1px solid #e5e5e5;
}

.side_bottom {
  padding-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #e5e5e5;
}
.revise_contaner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 500px;
  width: 100%;
  height: 100%;
  z-index: 999;
  // display: none;
}

.revise_img_contaner{
  margin-left: 500px;
  padding-top: 5%;
  @media (min-device-width: 1200px) and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    padding-top: 55px;
}
@media only screen and (min-width: 1367px) {
  grid-row: 1;
  grid-column: 1;
  margin-top: 25px;
}
}
.revise_img {
    max-width: 400px;
    min-height: 240px;
    height: auto;
    @media (min-device-width: 1200px) and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1) { 
      max-width: 350px;
}
}

.revise_nav {
  margin-top: 20px;
  border-radius: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-row: 2;
  grid-column: 1;
  gap: 15px;
  justify-self: center;
  align-self: start;
  text-align: center;
  cursor: pointer;
  @media only screen and (min-width: 1367px) {
    grid-row: 1;
    grid-column: 1;
    margin-top: 500px;
  }
}
.revise_nav_card {
  padding-top: 0px;
  background-color: #fff;
  border-radius: 12px;
  height: 100px;
  color:#51A88D;
  box-shadow: 0px 6px 8px #888888;
}
.revise_nav_card_active {
  padding-top: 10px;
  background-color: #51A88D;
  border-radius: 12px;
  height: 100px;
  color:#fff
}
.revise_nav_img {
  width: 85%;
  height: 70px;
  object-fit: contain;
  display: grid;
  justify-self: center;
  margin: 0 auto;
  padding-bottom: 4px;
  
}
.revise_btn_contaner {
  grid-row: 2;
  grid-column: 1;
  margin-top: 135px;
  @media only screen and (min-width: 1367px) {
    grid-row: 1;
    grid-column: 1;
    margin-top: 700px;
  }
}
.recject_btn {
  background-color: #fff;
  width: 200px;
  height: 50px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #51A88D;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 6px 8px #888888;
}
.recject_btn_active {
  background-color: #51A88D;
  width: 200px;
  height: 50px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #fff;
  padding-top: 9px;
  border-radius: 4px;
  box-shadow: 0px 6px 8px #888888;
  justify-self: center;
  align-self: start;
}
.options_btn {
  background-color: #fff;
  position: absolute;
  bottom: 3.5%;
  right: 2%;
  display: grid;
  cursor: pointer;
  border: none;
  outline: none;
  width: 200px;
  height: 35px;
  color: #51A88D;
  padding-top: 9px;
  border-radius: 4px;
  box-shadow: 0px 6px 8px #888888;
}
.model {
  position: fixed;
  left: 0;
  top: 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
}
.model_content {
  display: grid;
  grid-template-columns: 1fr;
  background-color: #fefefe;
  margin: 5% auto;
  border: none;
  border-radius: 10px;
  width: 100%;
  max-width: 580px;
  min-width: 30px;
  height: 300px;
.btn_grid {
  margin: 10px 20px 10px 0px;
  display: grid;
}
.submit_btn {
  background-color: #fff;
  color: #51A88D;
  font-size: 18px;
  border-radius: 12px;
  padding: 0px 20px;
  height: 40px;
  border: none;
  justify-self: end;
  -webkit-align-self: center;
  align-self: center;
  cursor: pointer;
  box-shadow: #00000029 0px 3px 6px;
  outline: none;
}
.textarea_box {
  margin: 20px 20px 0px 20px;
  outline: none;
  resize: none;
}
}
.revise_textarea_contaner {
  grid-column: 1;
  grid-row: 2;
  @media only screen and (min-width: 1367px) {
    grid-row: 1;
    grid-column: 1;
    margin-top: 600px;
  }
}
.textarea_preview
{
    margin-top: 200px;
    resize: none;
}